<template>
  <div>
    <!--begin::DocumentVQA-->
    <div class="row">
      <div class="col-lg-12 col-xxl-12">
        <p>
          To efficiently pass information to the reader, writers often assume
          that structured forms such as tables, graphs, or infographics are more
          accessible than sequential text due to human visual perception and our
          ability to understand a text's spatial surroundings. We propose a
          suite of tasks that balance factors such as quality of a document,
          importance of layout information, type and source of documents, task
          goal, and the potential usability in modern applications.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8 col-xxl-8">
        <div class="card card-custom card-stretch gutter-b">
          <!-- <div class="card-header border-0 py-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="text-muted mt-3 font-weight-bold font-size-sm">
                Owing to its end-to-end nature and heterogeneity, Document
                Understanding is the touchstone of Machine Learning.
              </span>
            </h3>
          </div> -->
          <div class="card-body px-0 py-0">
            <img style="max-width: 100%" src="/media/problem.png" />
          </div>
        </div>
      </div>
      <div class="col-xxl-4">
        <StatWidget7
          category="overall ranking"
          :progress="list.map(a => a.overall).reverse()"
          color="info"
        ></StatWidget7>
        <Paper></Paper>
      </div>
      <div class="col-lg-12 col-xxl-12">
        <ListWidget3 :list="list"></ListWidget3>
      </div>
    </div>
    <!--end::DocumentVQA-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ListWidget3 from "@/view/content/widgets/list/Widget3.vue";
import StatWidget7 from "@/view/content/widgets/stats/Widget12.vue";
import Paper from "@/view/content/widgets/due/Paper.vue";
import { mapState } from "vuex";

export default {
  name: "leaderboard",
  components: {
    ListWidget3,
    StatWidget7,
    Paper
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Document Understanding Benchmark" }
    ]);
  },
  computed: {
    ...mapState({
      list: state =>
        state.auth.leaderboard
          .filter(item => item.overall > 0)
          .sort((a, b) => {
            return a.overall - b.overall;
          })
          .reverse()
    })
  }
};
</script>
